




import SecurePollResults from "@/components/SecureVotingPolls/SecurePollResults.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    SecurePollResults,
  },
})
export default class SecurePoll extends Vue {}
