


























import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Poll,
  PollAnswer,
  SVPollType,
} from "@/services/api/secure-votes.service.types";
import SecurePollResult from "./SecurePollResult.vue";
import { PollActions, PollGetters } from "./types";

@Component({
  components: {
    SecurePollResult,
  },
})
export default class SecurePollResults extends Vue {
  @Getter(PollGetters.Polls)
  readonly polls!: Poll[];

  @Action(PollActions.FetchPoll)
  fetchPoll!: (id: string) => Promise<void>;

  poll: Poll | null = null;
  preVotePoll: Poll | null = null;

  absolute = false;
  abstainedVotesVisible = false;
  thresholdVisible = false;

  async created(): Promise<void> {
    await this.fetchPoll(this.$route.params.pollId);
    const pollIndex = this.polls.findIndex(
      (poll: Poll) => poll.questionId === this.$route.params.pollId
    );
    if (pollIndex !== -1) {
      if (this.$route.query.prevoteQuestionId) {
        await this.fetchPoll(this.$route.query.prevoteQuestionId as string);
        const preVotePollIndex = this.polls.findIndex(
          (poll: Poll) =>
            poll.questionId === this.$route.query.prevoteQuestionId
        );
        if (preVotePollIndex !== -1) {
          this.preVotePoll = this.polls[preVotePollIndex];
        }
      }
      this.poll = this.polls[pollIndex];
      this.absolute = this.poll.type === SVPollType.ELECTION;
      this.abstainedVotesVisible = this.poll.type === SVPollType.POLL;
      this.thresholdVisible = this.poll.type === SVPollType.POLL;
    }
  }

  get answerVotes(): PollAnswer[] {
    if (this.poll) {
      if (this.poll.type === SVPollType.POLL && this.preVotePoll) {
        const preVotePoll: Poll = this.preVotePoll;
        // add pre vote count to the vote count of the poll
        return this.poll.answerVotes.map((answer) => {
          const index = preVotePoll.answerVotes.findIndex(
            (preVoteAnswer) =>
              preVoteAnswer.value.trim().toLowerCase() ===
              answer.value.trim().toLowerCase()
          );
          if (index !== -1) {
            const answerCopy = { ...answer };
            answerCopy.voteCount =
              (answerCopy.voteCount ?? 0) +
              (preVotePoll.answerVotes[index].preVoteCount ?? 0);
            return answerCopy;
          }
          return answer;
        });
      }
      return this.poll.answerVotes;
    }
    return [];
  }

  get abstainedVotes(): number {
    const abstainedVotesIndex = this.answerVotes.findIndex(
      (answer: PollAnswer) => answer.value.trim().toUpperCase() === "ENTHALTUNG"
    );
    if (abstainedVotesIndex && abstainedVotesIndex !== -1) {
      return this.answerVotes[abstainedVotesIndex].voteCount ?? 0;
    }
    return 0;
  }

  get answers(): PollAnswer[] {
    if (this.poll) {
      if (this.poll.type === SVPollType.POLL) {
        return this.answerVotes.filter(
          (answer: PollAnswer) =>
            answer.value.trim().toUpperCase() === "JA" ||
            answer.value.trim().toUpperCase() === "NEIN"
        );
      }
      return this.answerVotes;
    }
    return [];
  }

  get total(): number {
    if (!this.poll) {
      return 0;
    }

    const total = this.answers.reduce((a, b) => {
      return a + (b.voteCount ?? 0);
    }, 0);
    return total;
  }

  get uniqueVotes(): string {
    switch (this.poll?.type) {
      case SVPollType.ELECTION:
        // Number of ballots, even empty ballots
        return this.$tc("polls.voter", this.poll.uniqueVotes, {
          num: this.poll.uniqueVotes,
        });
      case SVPollType.POLL:
        // Sum of all "Ja" and "Nein" votes for this question (without "Enthaltung")
        return this.$tc("polls.castVote", this.total, { num: this.total });

      default:
        return "";
    }
  }

  get thresholdClasses(): string {
    if (
      this.thresholdVisible &&
      (this.$route.query.threshold === "50" ||
        this.$route.query.threshold === "66" ||
        this.$route.query.threshold === "75")
    ) {
      return `threshold threshold--${this.$route.query.threshold}`;
    }
    return "";
  }

  calcPercent(votes?: number, decimals = 0): number {
    if (this.total === 0 || !votes) {
      return 0;
    }

    return (
      Math.round((votes / this.total) * Math.pow(10, decimals + 2)) /
      Math.pow(10, decimals)
    );
  }

  progressColor(type: SVPollType, answer: PollAnswer): string {
    if (type === SVPollType.ELECTION) {
      return "#FFA500";
    } else if (type === SVPollType.POLL) {
      if (answer.value.trim().toUpperCase() === "JA") {
        return "#008000";
      } else if (answer.value.trim().toUpperCase() === "NEIN") {
        return "#FF0000";
      }
    }
    return "brand";
  }
}
