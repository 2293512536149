
















import { PollAnswer } from "@/services/api/secure-votes.service.types";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class SecurePollResult extends Vue {
  @Prop({ required: true }) answer!: PollAnswer;
  @Prop({ default: false }) absolute!: boolean;
  @Prop({ default: 25 }) barHeight!: number;
  @Prop({ required: true }) percent!: number;
  @Prop({ default: "brand" }) progressColor!: string;

  get progressBackgroundColor(): string {
    return this.$vuetify.theme.dark
      ? "background lighten-1"
      : "background darken-1";
  }

  get voteDisplay(): string {
    return this.absolute
      ? `${this.answer.voteCount}`
      : `${this.answer.voteCount} (${this.percent.toFixed(2)}%)`;
  }
}
